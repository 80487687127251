import {Styled} from "./ErrorModal.styled";
import CloseIcon from "../../assets/icons/CloseIcon";



const ErrorModal = ({img, text, contact, handleClose}) => {

  return(
      <Styled.Wrapper>
        <Styled.ModalWrapper>
          <button onClick={handleClose}>
            <CloseIcon/>
          </button>
          <Styled.ModalContainer>
            <Styled.ImgContainer>
              <img src={img} alt=""/>
            </Styled.ImgContainer>
            <div>
              <span>{text}</span>
              <p>{contact}</p>
            </div>
          </Styled.ModalContainer>
        </Styled.ModalWrapper>
      </Styled.Wrapper>
  )
}
export default ErrorModal
