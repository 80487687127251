import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";

const Main = styled("div")`
`;

const DropDownContainer = styled("div")`
  position: relative;
  margin: 0 auto;
`;

const DropDownHeader = styled("input")`
  height: 48px;
  width: 221px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #e0cfe0;
  background-color: #fff;
  cursor: default;
  &:focus {
    border: 2px solid #000000;
  }
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  max-width: 221px;
  z-index: 2;
  background: #ffffff;
  border: 1px solid #626161;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 7px 0 #938e8e; 
  border-radius: 4px;
  overflow:  ${(props) => (props?.scroll ? "scroll": "none")};
  max-height: 400px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
`;

const ListItem = styled("li")`
  list-style: none;
  cursor: default;
  padding-left: 12px;
  background: ${(props) => (props?.selectedOption && "#5554a9")};
  &:hover {
    background-color: #5554a9;
    color: #FFFFFF;
  }
`;
const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};

const ReusableSelect =({ optionsData, handleChange, scroll, value}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const toggling = () => {
        setIsOpen(true);
    }
    const handleClickOutside = () => {
        setIsOpen(false)
    };
    const ref = useOutsideClick(handleClickOutside);
    useEffect(()=>{
        handleChange(selectedOption)
    },[selectedOption])
    const onOptionClicked = value => () => {
        if(value === "Choose") {
            setSelectedOption("");
        }else {
            setSelectedOption(value);
        }
        setIsOpen(false);
    };
    const options = ["Choose",...optionsData]

    return (
        <Main>
            <DropDownContainer>
                <DropDownHeader onClick={toggling} value={value || "Choose"} ref={ref} type="button"/>
                {isOpen && (
                    <DropDownListContainer scroll={scroll} >
                        <DropDownList>
                            {options.map(option => (
                                <ListItem onClick={onOptionClicked(option)} key={Math.random()} selectedOption={value === option}>
                                    {option}
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        </Main>
    );
}
export default ReusableSelect
