import styled from "styled-components";


const WrapperClose = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #a19898;
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  svg {
    position: absolute;
  }
`



const CloseIcon = () => {
  return(
    <WrapperClose>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L15.0275 15.0275" stroke="#1B1B1E" stroke-linecap="round"/>
        </svg>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.0312 1L1.00376 15.0275" stroke="#1B1B1E" stroke-linecap="round"/>
        </svg>
    </WrapperClose>
  )
}
export default CloseIcon
