import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color:  rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`
const ModalWrapper = styled.div`
  width: 100%;
  max-width: 988px;
  background-color: #FFFFFF;
  height: 347px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 45px rgba(87, 88, 134, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 12px;
   button {
     border: none;
     background: none;
     //width: 100%;
     //display: flex;
     //justify-content: end;
     float: right;
   }
`
const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //padding: 10px 12px 20px 12px;
  //box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  span {
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    display: flex;
    align-items: center;
  }
  p {
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
  }
`

const ImgContainer = styled.div`
  width: 100%;
  max-width: 240px;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
  }
`
export const Styled = { Wrapper, ModalWrapper, ImgContainer, ModalContainer }
