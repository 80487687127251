const PrivacyPolicyModal = () => {
    return(
        <>
          <p>How we use and protect your personal data</p>
          <p>Who we are</p>
          <p>
              Manitorium Ltd (Manitorium ) is committed to protecting and respecting your privacy.  Manitorium is a limited company.
              Any reference to ‘us’, ‘our’ or ‘we’ in this
              Privacy Policy is a reference to Manitorium Ltd.  Similarly, any reference to ‘you’, ‘your’, ‘yours’ or ‘yourself’ in
              this Privacy Policy is a reference to any of our past, prospective or current membership, employee, government, agency, partner,
              media or other public or industry contacts. Collectively “data subjects”.
          </p>
          <p>
              This Privacy Policy (Notice) describes your rights, the information or “Personal Data” we would usually collect and use and how we would protect it.
          </p>
          <p>Our commitment and obligations to you</p>
          <p>
              We take the collection, usage, and security of your personal data seriously. We can only use your personal data under the law if we have a good reason
              for  doing so. The law provides examples of those reasons. These include:
          </p>
          <p>
              to perform or fulfill an agreement we have with you;
              if we have a legal duty;
              if it is within our legitimate business interest;
              if there is a public interest reason for doing so; or
              if you have given your consent.
              A legitimate interest is when we have a business or commercial reason to use your information. But even then it must not unfairly go against what is right and
              best for you. If we rely on our legitimate interest, we will tell you what that is.
          </p>
          <p>
              Types of personal data
              Your personal information (for example, your name and date of birth)
              Contact details (for example, your postal address, phone number, email address or mobile number)
              Customer relationship data (for example, notes of calls or requests you may have made or attendance at an event or webinar)
              Payment transactions data (for example, when you use the webshop)
              Financial information (for example, bank account numbers)
              Online profile and social media information
              Communications data
              Technical Information includes internet protocol (IP) address, your login data, browser type,
              and version, time zone setting, browser plug-in types and versions, operating system, and platform.
          </p>
          <p>
              Location data
              User login and subscription data
              Economic crime-related information (e.g. financial crime and fraud information)
              Investigations data
              Education and employment information
              Visual images and personal appearance (such as copies of passports or CCTV images)
              Information about your family, lifestyle, and social circumstances (such as dependants, marital status, next of kin, and contact details)
              On occasion the following special category (sensitive) personal data may be obtained: physical or mental health details, political opinion, racial or ethnic origin,
              and religious beliefs.  We will only obtain and process this information with your consent (permission) or in situations where it is in the wider public interest.
          </p>
          <p>
              If you choose to provide us with any personal data relating to a third party (for example, information relating to your spouse, children, parents, and/or
              employees) or ask us to share their personal data with third parties by submitting such information to us, you confirm that they understand the information in this
              notice about how we will use their personal data.
          </p>
          <p>How is the personal data obtained?</p>
          <p>
              We obtain this information in several ways, for example through your use of our services or other dealings with us, including through the member onboarding
              process, inquiry forms, and from information provided in the course of ongoing correspondence. We may collect personal data from you:
          </p>
          <p>
              When you fill in forms, visit the member portal or the webshop on our website Manitorium, com (“our site”), or when you correspond with us by phone, email, or
              otherwise. This includes information you provide when you subscribe to our training, events, and/or services, complete a survey, sign up for a newsletter, post
              material on our site, report a problem with our site, or request further services.
              When applying for a role or position within Manitorium either online, via third-party sites, and/or through direct contact made with Manitorium.
              When you browse other sites which we operate or support.  In such cases, please refer to the privacy policy/ notice contained on the relevant site.
              With regard to each of your visits to our site we may collect the following information:
          </p>
          <p>
              technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type, and
              version, time zone setting, browser plug-in types and versions, operating system, and platform;
              information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through, and from our site (including date and time); products you
              viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and
              mouse-overs), cookies and methods used to browse away the page and any phone number used to call our customer service number.
              Further, if you visit our offices or premises, we may have CCTV which may record your image.
          </p>
          <p>Information received from events, training, and working groups/committees</p>
          <p>
              We may receive information from other sources, such as from our members, associate members, government officials, law enforcement and fraud prevention
              agencies, regulatory bodies, our other partners for the purpose of providing services to our members. This includes supporting members and law enforcement
              and fraud prevention agencies to combat, prevent and detect economic criminal activity. The information received may include names, addresses, bank account
              details, transaction information, criminal convictions and cautions and indications of potential or actual criminal
              Offers and promotions to you via our site, any other websites we operate or other services we provide, advertising networks and analytics providers or publicly
              accessible data.
          </p>
          <p>
              What do we use your personal information for?
              To carry out our obligations arising from any agreements entered into between you and us and to provide you with the information, products, and services that
              you request from us, including providing membership services to you, including events, training, and working groups.
              To ensure that content from our site is presented in the most effective manner for you and for your computer or device or to provide you with content that we feel
              may interest you, where (if required to do so) you have consented to be contacted for such purposes:
              to allow you to participate in interactive features of our services when you choose to do so; and
              to notify you about changes to our services.
              As a business, it is critical that we perform our agreement with you with the best service possible, and it is in our legitimate interest to be responsive to you and
              to ensure the proper functioning of our products and organization.
          </p>
          <p>How we share your personal data</p>
          <p>We take your privacy very seriously and we’ll only share your information where:</p>
          <p>
              we need to for the purposes of providing you with products or services you have requested;
              we have a public or legal duty to do so e.g. to assist with detecting fraud and tax evasion, economic crime prevention, regulatory reporting, litigation or
              defending legal rights;
              we have a legitimate reason for doing so e.g. to manage risk, to assess your suitability for services, or to enable one of our virtual activity sponsors to promote
              themselves to you; or
              we have asked you for your permission to share it, and you’ve agreed.
          </p>
          <p>Third parties</p>
          <p>
              We may occasionally share your data with trusted third parties to help us deliver efficient and quality services. We will never sell, trade, or rent your personal
              data to others; however, we may share your information with selected third parties including:
          </p>
          <p>
              business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;
              financial institutions in the performance of our role to support with economic crime information sharing initiatives; providing services for our members or where
              we are otherwise directed by you to share information with them;
              fraud prevention agencies who will use it to prevent fraud and money laundering and to verify your identity. If fraud is detected, you could be refused certain
              services, finance or employment;
              provided you have consented; marketing, market research, advertisers and advertising networks that require the data to select and serve relevant adverts to you
              and others;
              analytics and search engine providers that assist us in the improvement and optimisation of the website;
              any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries for the purposes set out above;
              in the event that we buy or sell any business or assets, including the sale of an individual website owned by us, in which case we may disclose your personal data
              to the prospective seller or buyer of such business or assets; and
              if Manitorium is acquired by (or all of its assets are acquired by) a third party, in which case personal data held by it about its members and customers will be
              one of the transferred assets.
          </p>
          <p>Economic crime information sharing</p>
          <p>
              We may share your personal data with law enforcement agencies, fraud prevention agencies, public authorities, or other organizations if legally required to do
              so, or if we have a public interest and/or good faith belief that such use is reasonably necessary to:
          </p>
          <p>
              comply with a legal obligation, process, or request (including responding to any requests from law enforcement authorities outside the EEA, as defined below);
              enforce our agreements, including investigation of any potential violation thereof;
              detect, prevent or otherwise address economic criminal activity (including financial crime, fraud, money laundering etc);
              detect, prevent or otherwise address security, fraud or technical issues with our services and site; or
              protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law.
              Sharing aggregated/anonymized data
          </p>
          <p>
              Where we have made your information anonymous, we may share this outside of Manitorium with partners such as research groups, universities, advertisers or
              connected sites. For example, we may share information publicly to show trends in the financial services market.
          </p>
          <p>Links to external websites</p>
          <p>
              Our sites may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates.  If you follow a link to any of these
              websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies or how such
              websites collect and use your data. Please check these policies before you submit any personal data to these websites.
          </p>
          <p>Where we store or transfer your personal data</p>
          <p>
              We would usually ensure that the majority of personal data we store or process is within the UK or European Economic Area (EEA).
          </p>
          <p>
              There may be situations where the data that we collect from you may be transferred to, and stored at, a destination outside the UK and EEA”), including, in
              particular, the United States. It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers or business partners. We
              will ensure they agree to apply equivalent levels of protection for personal data. If this is not possible – for example because we are required by law to disclose
              information – we will ensure the sharing of that information is lawful. Also, if they are not in a jurisdiction that is regarded as having “adequate” levels of
              protections for personal data, we will put in place appropriate safeguards (such as contractual commitments), in accordance with applicable legal requirements,
              to ensure that your data is adequately protected.
          </p>
          <p>Security of your personal data</p>
          <p>
              We always take appropriate technical and organizational measures to ensure that your information is secure. In particular, we train our employees who handle
              personal data to respect the confidentiality of customer information and the privacy of individuals. We regard breaches of your privacy very seriously and will
              impose appropriate penalties, including dismissal where necessary. We have appointed a Data Protection Officer to ensure that our management of personal
              data is in accordance with this Privacy Notice and the applicable legislation. The transmission of information via the internet is not completely secure. Although
              we will do our best to protect your personal data, we cannot guarantee the security of the data transmitted to our site; any transmission of your data is at your
              own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.
          </p>
          <p>How long we keep your information</p>
          <p>
              We will retain your personal information for as long as you use our services and for a reasonable time thereafter. After you have terminated your use of our
              services, we will retain your personal information for up to seven years and thereafter may store it in an aggregated and anonymized format.
          </p>
          <p>Your rights</p>
          <p>Data protection laws give you certain rights in relation to the data we hold on you. These include the following rights to:</p>
          <p>
              request a copy of the personal data we hold about you;
              request that we supply you (or a nominated third party) with a copy of the personal data that you provided to us;
              inform us of a correction to your personal data
              exercise your right to restrict our use of your personal data
              exercise your right to erase your personal data; or
              object to the ways in which we are using your personal data.
              Your ability to exercise these rights will depend on a number of factors and in some instances, we will not be able to comply with your request e.g. because we
              have legitimate grounds for not doing so or where the right doesn’t apply to the particular data we hold on you.
          </p>
          <p>
              Where you have provided consent to our use of your data, you also have the unrestricted right to withdraw that consent at any time. Withdrawing your consent
              means that we will stop processing the data that you had previously given us consent to use. There will be no consequences for withdrawing your consent.
          </p>
        </>
    )
}
export default PrivacyPolicyModal

