const DisclaimerModal = () => {
    return (
        <>
            <p>
                Stock markets are volatile and can fluctuate significantly in response to company, industry,
                political, regulatory, market, or economic developments. Investing in
                stock involves risks, including the loss of principal.
            </p>
            <p>
                Diversification and asset allocation do not ensure a profit or guarantee against loss
            </p>
            <p>
                The projections or other information generated by Manitorium analytical tools  regarding the likelihood of various investment outcomes are hypothetical in nature
                and are not guarantees of future results. Results may vary with each use and over time.

            </p>
            <p>
                Clients are strongly recommended to seek independent financial, legal and tax advice before proceeding with any trade. Any information in this site should not
                be read, interpreted or construed as constituting advice on the part of Manitorium or any of its affiliates, directors, officers or employees.
            </p>
        </>
    )
}
export default DisclaimerModal
