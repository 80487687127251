import { useEffect, useState } from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {new_color, setPortfolioType} from "../../reduser";
import { NavLink } from "react-router-dom";

const Portfolio = styled.div`
      height: auto;
      box-sizing: border-box;
      z-index: 1;
      position: relative;
      display: flex;
      padding: 10px 0;
      border-radius: 12px;
      background: ${(props) =>
        props.color ? "rgba(84,85,169,1)" : "#c0c1ff"};
      justify-content: space-between;     
      margin: 0 0 20px;      
        @media (max-width: 1200px) {  
          display: block;
          height: auto;        
        }
        @media (max-width: 600px) {
          padding-bottom: 10px;
        } 
     
      .buttons {       
        width: 16%;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: ${(props) =>
        props.color ? "inherit" : "#c0c1ff"};
        @media (max-width: 1200px) {    
           width: 100%;
            float: none;
            text-align: left;
        }
       button, a.link_to_buy {
          text-align: center;
          text-decoration: none;
          height: 30px;
          line-height: 30px;
          width: 80%;
          font-weight: 500;
          display: block;          
          border-radius: 20px;
          border: 1px solid rgba(57, 255, 113, 1);
          color: rgba(57, 255, 113, 1);
          background: ${(props) => (props.color ? "#fff" : "#000")};
          margin: 15px auto;
          cursor: pointer;
          font-size: 12px;         
          box-shadow: 0 0 0 8px ${(props) => (props.color ? "#fff" : "#000")};
          
          @media (max-width: 1200px) {        
            width: 47%;
            display: inline-block;
            margin: 0 1.5% 10px;                
          }
          @media (max-width: 600px) {        
            width: 90%;
            margin: 10px auto 10px;
            display: block;

          }  
      } 
      button.red, a.red {
        margin-top: 30px;
        border: 1px solid #FF3939;
        color: #ff3939;
      }
    }

`;
const Span = styled.span`
  color: ${(props) => (props.color ? "#fff" : "#242477")};
  background: ${(props) => (props.color ? "inherit" : "#c0c1ff")};
  border-radius: 12px;
  display: inline-block;
  width: 29%;
  height: 108px;
  box-sizing: border-box;
  font-weight: 500;
  position: relative;
  padding: 27px;
  @media (max-width: 1200px) {
    width: 45.7%;
    margin: 10px 0;
    padding: 27px 0 0 20px;
  }
  @media (max-width: 600px) {
    width: 80%;
    padding: 0 0 0 20px;
    height: 60px;
  }
  svg {
    float: right;
    border: 1px solid ${(props) => (props.color ? "#A2A3FD" : "#3C3D8F")};
    padding: 5px;
    border-radius: 50px;
  }

  &:after {
    position: absolute;
    font-size: 20px;
    left: 27px;
    top: 57px;
    font-weight: 700;
    width: calc(100% - 60px);
    padding-top: 3px;
    border-top: 2px solid ${(props) => (props.color ? "#A2A3FD" : "#5455A9")};
    content: "${(props) => props.data}";
    color: ${(props) => (props.color ? "#fff" : "#242477")};
    @media (max-width: 1200px) {
      width: calc(100% - 20px);
      left: 20px;
    }
    @media (max-width: 600px) {
      top: 30px;
    }
  }
`;
const SpanTwo = styled(Span)`
  &:after {
    content: "$${(props) => props.data}";
  }
`;
const SpanThree = styled(Span)`
  &:after {
    content: "$${(props) => props.data}";
  }
`;
export default function DataPortfolio({
  numberStock,
  viewPortfolioData,
  setViewBuyPortfolio,
  setSavePortfolio,
  potential_profit,
  total_cost,
  broker, capital }) {
    const color = useSelector(new_color);
    const dispatch = useDispatch();
    return (
        <Portfolio color={color}>
            <Span data={numberStock} color={color} className="one">
                Number of stocks{" "}
                <svg
                   width="10"
                   height="10"
                    color={color}
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                        fill={color ? "#A2A3FD" : "#3C3D8F"}
                        stroke={color ? "#A2A3FD" : "#3C3D8F"}
                    />
                </svg>{" "}
            </Span>
            {viewPortfolioData === 'equil'  ?  <Span data={capital.toFixed(2)} color={color} className="one">
                Equaly Portfolio Value
                <svg
                  width="10"
                  height="10"
                    color={color}
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                        fill={color ? "#A2A3FD" : "#3C3D8F"}
                        stroke={color ? "#A2A3FD" : "#3C3D8F"}
                    />
                </svg>{" "}
            </Span> : null}
           {viewPortfolioData === 'min' ? <Span data={total_cost.toFixed(2)} color={color} className="one">
                Minimum Portfolio Value
                <svg
                  width="10"
                  height="10"
                    color={color}
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                        fill={color ? "#A2A3FD" : "#3C3D8F"}
                        stroke={color ? "#A2A3FD" : "#3C3D8F"}
                    />
                </svg>{" "}
            </Span> : null }

            {broker && capital && <Span data={broker} color={color} className="one">
                Broker
                <svg
                   width="10"
                   height="10"
                    color={color}
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                        fill={color ? "#A2A3FD" : "#3C3D8F"}
                        stroke={color ? "#A2A3FD" : "#3C3D8F"}
                    />
                </svg>{" "}
            </Span>}
            {potential_profit && <>
                <SpanTwo data={total_cost} color={color} className="two">
                    Total cost{" "}
                    <svg
                        width="10"
                        height="10"
                        color={color}
                        viewBox="0 0 5 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                            fill={color ? "#A2A3FD" : "#3C3D8F"}
                            stroke={color ? "#A2A3FD" : "#3C3D8F"}
                        />
                    </svg>{" "}
                </SpanTwo>
                <SpanThree data={potential_profit ? potential_profit.toFixed(2) : 0} color={color} className="three">
                    Potential profit{" "}
                    <svg
                        width="10"
                        height="10"
                        color={color}
                        viewBox="0 0 5 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.08459 3.47889L3.0856 3.47881C3.09773 3.47783 3.10815 3.47736 3.11703 3.47722C3.11739 3.47947 3.11775 3.48183 3.1181 3.4843C3.12768 3.55087 3.12559 3.64524 3.11086 3.76432C3.09903 3.85993 3.08234 3.94901 3.06562 4.03832C3.06284 4.05311 3.06007 4.06791 3.05732 4.08275C2.92354 4.58468 2.76006 5.14462 2.59726 5.70224C2.48898 6.07309 2.38101 6.44292 2.28227 6.79398C2.16013 7.22822 2.05271 7.63225 1.98377 7.95398C1.94947 8.11405 1.92226 8.26481 1.90859 8.39434C1.89824 8.49235 1.88351 8.667 1.93609 8.82664C1.97211 8.9404 2.03826 9.04906 2.13808 9.13305L2.13674 9.13387C2.04827 9.18823 1.8941 9.27606 1.71938 9.35312C1.53814 9.43306 1.36859 9.48616 1.24058 9.49774C1.1971 9.50168 1.17002 9.50026 1.15438 9.49798C1.14131 9.46092 1.11535 9.3498 1.15143 9.09736C1.35861 7.97173 1.62563 7.03674 1.84077 6.2834C1.93847 5.94129 2.02547 5.63665 2.09131 5.36863C2.14181 5.16304 2.1827 4.96549 2.19729 4.79389C2.20935 4.65209 2.21832 4.3993 2.07539 4.1867C2.03317 4.12391 1.98106 4.06964 1.92095 4.02576C2.11112 3.91118 2.29949 3.80437 2.47504 3.71486C2.78996 3.55428 2.99522 3.48593 3.08459 3.47889ZM3.16035 3.48063C3.16029 3.48069 3.15925 3.48047 3.15738 3.47978C3.15947 3.48023 3.1604 3.48057 3.16035 3.48063ZM3.10709 3.4366C3.10672 3.43591 3.10656 3.43553 3.10657 3.43552L3.10709 3.4366ZM1.13626 9.49306C1.13648 9.49286 1.13858 9.49346 1.14188 9.49545C1.13769 9.49426 1.13604 9.49326 1.13626 9.49306ZM2.91996 0.639379C3.01166 0.553459 3.1426 0.5 3.28541 0.5C3.42823 0.5 3.55917 0.553459 3.65087 0.639379C3.74147 0.724266 3.78571 0.832173 3.78571 0.937244C3.78571 1.04232 3.74147 1.15022 3.65087 1.23511C3.55917 1.32103 3.42823 1.37449 3.28541 1.37449C3.1426 1.37449 3.01166 1.32103 2.91996 1.23511C2.82936 1.15022 2.78511 1.04232 2.78511 0.937244C2.78511 0.832173 2.82936 0.724266 2.91996 0.639379Z"
                            fill={color ? "#A2A3FD" : "#3C3D8F"}
                            stroke={color ? "#A2A3FD" : "#3C3D8F"}
                        />
                    </svg>{" "}
                </SpanThree> </>}
            {capital ?
                <div className="buttons">
                    <button  onClick={()=>setSavePortfolio(false)}>
                        Save Portfolio
                    </button>
                    {/*<button className="red" onClick={() => setViewBuyPortfolio(false)}}>Buy Portfolio</button>*/}
                </div>
                :
                <div className="buttons">
                    <NavLink to='/buyportfolio'
                     state={{
                      numberStock: numberStock,
                      broker: broker,
                     total_cost: total_cost,
                    }}
                    onClick={() => dispatch(setPortfolioType("long"))}
                    className="link_to_buy"
                    >
                    <span>
                       Build LONG portfolio
                    </span>
                    </NavLink>
                    <NavLink to='/buyportfolio'
                     state={{
                      numberStock: numberStock,
                      broker: broker,
                      total_cost: total_cost,
                    }}
                     onClick={() => dispatch(setPortfolioType("short"))}
                    className="link_to_buy red"
                    >
                    <span >Build  SHORT portfolio</span>
                    </NavLink>
                </div>
            }

        </Portfolio>

    )
};
