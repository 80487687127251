const TermsAndConditionsModal = () =>{
    return(
        <>
            <p>Welcome to the Manitorium website and all the information provided through it.</p>
            <p>Welcome to the Manitorium website and all the information provided through it.</p>
            <p>
                These Terms and Conditions (“Terms”) apply to the use of this website at www.manitorium,com (“Site”) by the end-user (“User”). The User agrees to be bound by these Terms and
                Conditions each time the website is accessed. If the User does not accept these Terms, the Site must not be used.
            </p>
            <p>The Manitorium Privacy and Cookies Policy also applies to the use of this Site.</p>
            <p>1. Use of the Site
                Manitorium do not charge you for access to this Site, however, you may incur charges by your internet service provider when accessing the Site. You shall be liable for all charges that
                your internet service provider charges you for providing you with their service.
            </p>
            <p>
                You acknowledge that transmissions via the Site may present some risks inherent to any internet communications and that you accept such risks by communicating with us over the
                internet.
            </p>
            <p>
                Certain sections and pages of the Site, including some Publications for download, require you to register or login using your username and password and, in some instances, pay (see
                section 13 below for more details). If registration or payment is requested, you agree to provide complete and accurate information. Each registration is for a single individual unless
                described otherwise on the registration page. It is your responsibility to ensure that nobody, except you, uses your name/username and password to enter the sections of the Site which
                require them.
            </p>
            <p>
                To be able to access a restricted area of the Site, you will require your individual credentials and password (“account details”).
            </p>
            <p>
                You must only use the Site in accordance with these Terms and all applicable laws, regulations and codes of conduct (including self-regulatory) applicable to the use of the Internet and
                you agree to be solely responsible for all consequences arising from your use of the Site. Subject to these Terms, you are only permitted to print and download extracts from this Site for
                your own use on the following basis:
            </p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;Documents, tables and/or graphics that are downloaded are not modified in any way from their original format without prior written permission from the author or the content team
                at Manitorium;
                b.&nbsp;&nbsp;&nbsp;&nbsp;no graphics on this Site are used separately from accompanying text; and
                c.&nbsp;&nbsp;&nbsp;&nbsp;Manitorium’s copyright and trademark notices (contact press@ukfinance.org.uk for the applicable notices) appear in all copies.
            </p>
            <p>
                The content available on the Site is made available for your own personal, non-commercial use only.
            </p>
            <p>
                When you are contacting us, we may require your name, email address and phone number (if the request is urgent), in order for us to answer the inquiry. These details constitute
                personal data and we will treat personal data as confidential. For more information on how we handle personal data, please read our Privacy and Cookies Policy.
            </p>
            <p>
                Any material transmitted or posted to the Site shall be considered non-confidential and non-proprietary, and we shall have no obligations in respect of such material.
            </p>
            <p>
                2. Misuse of the Site
                You may not misuse the Site in any manner (including, without limitation, as follows). You must not reproduce our name, trademarks, Site appearance, and the domain name or
                reproduce part or all of our proprietary information to send content online, via email or any digital communication. You shall not present yourself as a representative of us or mislead
                the public by presenting yourself as connected to us in any manner or use the Site in any way to send unsolicited (commercial or otherwise) e-mail (spamming) or any material for
                marketing or publicity purposes.
            </p>
            <p>
                We will take action against any communication made by you to impersonate us, our Site and any associated websites, servers, domains, IP addresses or social media presence. We
                expressly forbid the use of the Site or information to post or transmit any material:
            </p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;That is unlawful or would infringe the rights of any person (for example, material that is threatening, abusive or offensive, obscene or pornographic, defamatory, seditious or
                inflammatory, in breach of confidence, in breach of rights of privacy), or which may cause annoyance or inconvenience to any person;
                b.&nbsp;&nbsp;&nbsp;&nbsp;For which you have not obtained all necessary licenses and/or approvals;
                c.&nbsp;&nbsp;&nbsp;&nbsp;Which constitutes or encourages conduct that would be considered a criminal offense, give rise to civil liability, or otherwise be contrary to the law or infringe any third party’s
                rights, in any jurisdiction; or
                d.&nbsp;&nbsp;&nbsp;&nbsp;Which is technically harmful (including, without limitation, computer viruses, Trojan horses, worms, corrupted data or other malicious or harmful software, data or components). We
                will fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone posting any material in breach of these Terms
                and conditions.
            </p>
            <p>You warrant and undertake that you will not:</p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;use the Site except in accordance with these Terms;
                b.&nbsp;&nbsp;&nbsp;&nbsp;sub-license, assign or subcontract any part of your rights or obligations under the Terms;
                c.&nbsp;&nbsp;&nbsp;&nbsp;use or permit the use of the Site for any illegal purpose including but not limited to damages, interference with, or disrupted access to, the Site or do anything that may interrupt or
                impair its functionality;
                d.&nbsp;&nbsp;&nbsp;&nbsp;make or derive any commercial use or benefit from any part of the Site;
                e.&nbsp;&nbsp;&nbsp;&nbsp;use the Site in such a manner as would bring us, our organization and/or business into disrepute; or
                f.&nbsp;&nbsp;&nbsp;&nbsp;obtain or attempt to obtain unauthorized access, through whatever means, to the paid-for area of the Site.
            </p>
            <p>
                3. Suspension and Termination of Service
                We reserve the right to make changes or corrections, alter, suspend, discontinue or terminate any aspect of the Site, and your access to it, at any time immediately without notice.
            </p>
            <p>We shall not be liable if this Site is unavailable at any time or for any period of time.</p>
            <p>
                Your Account Details will remain live to enable you to use the Site at any time. However, if your Account Details are not used for an extended period of time, it will be deleted. You will
                need to re-register for the Site in this instance.
            </p>
            <p>You can ask for your Account Details to be closed and we will deactivate your Account Details by contacting websitefeedback@ukfinance.org.uk.</p>
            <p>We will not delete personal data where it is required to establish, defend or exercise legal claims. Otherwise, personal information will be retained for a period of up to seven years.</p>
            <p>
                4. Indemnity
                Except as expressly provided for below, you agree to indemnify and hold us, and all of our officers, employees and agents harmless from any actions, claims, losses, liability,
                proceedings, damages, costs, expenses, loss of business, loss of profits, business interruption and other pecuniary or consequential loss (including legal costs and expenses) suffered
                or incurred by us in respect of any claim brought by any third party arising in respect of these Terms and/or the Site and/or the information provided to you and/or your use or misuse
                of the Site.
            </p>
            <p>
                5. Content
                Under these Terms, we grant you a license to use the information in order to view it for your own personal, non-commercial use only.
            </p>
            <p>
                “Intellectual Property Rights” means all present and future intellectual property rights worldwide however arising and in whatever media, whether or not registered, including without
                limitation, patents, trademarks, design rights, copyright, database rights, service marks, trade names and rights in know-how and confidential information. All rights, title and interest to
                the Intellectual Property Rights which subsist or may subsist in the information and/or the Site vests in us (or third-party licensors) and you agree not to modify, reproduce, republish,
                upload, post, distribute or otherwise transmit or use the Intellectual Property Rights provided by us to you in any way without our prior written consent.
            </p>
            <p>You are not permitted to use any registered trademarks owned by us without our prior written consent.</p>
            <p>
                You are not permitted to use any service marks and/or other trade names or any logos, page headers, custom graphics or button icons used by us on the Site without our prior
                consent.
            </p>
            <p>Any trademarks on the Site not owned by us will be owned by third-party licensors and should not be used without their permission.</p>
            <p>
                On downloading any content from the Site, you understand and agree that any sharing, distribution or republishing of the content, without prior written authorization from the author or
                our content managers, shall be constituted as a breach of your membership privileges and in breach of these Terms.
            </p>
            <p>
                We follow best practice in IT security and uses encryption tools to keep data transmissions over the Site secure. However, you acknowledge that transmissions via the Site present some
                risks inherent to any Internet communications and that you accept such risks by communicating over the Internet.
            </p>
            <p>
                6. Copyright Guidelines for use by you of our publications and information
                The following guidelines apply if you wish to reproduce, in whole or in part, a Publication whether in hard copy and/or electronic format or otherwise.
            </p>
            <p>
                A “Publication” is defined as any document, blog, article or report, written and published by us.
                In all instances, you should obtain prior written permission from the Press Office (press@ukfinance.org.uk) before doing any of the restricted acts under the Copyright, Designs and
                Patents Act 1988 or anything which would amount to an infringement of Intellectual Property Rights in respect of all or any of the Publications and/or Information whether obtained from
                us in hard copy or presented to you on any form of removable hardware or distributed by us online through this Site, including and without limitation, any copying and/or distributing
                the Publications and/or Information in any format online or offline for internal use, use on intranets or for communication to the public. A license fee may be payable if we grant you the
                right to reproduce a Publication in whole or in part.
            </p>
            <p>Redistribution of the Publications on or via another website is not permitted without our prior written consent.</p>
            <p>Redistribution of the Publications via hard copy or email is not permitted without authorization from the author or without our prior written consent of us.</p>
            <p>
                A license fee may be payable if we grant you the right to reproduce the whole or part of a Publication. Redistribution of the Publications via another website is not permitted without our
                prior written consent.
            </p>
            <p>
                Links to Third Party websites: the Site may contain links to third party websites and to other resources (“Linked Sites”). We do not control, sponsor, endorse, approve or accept any
                responsibility for anything appearing in the Linked Sites (nor in other sites referred to in or linked to the Linked Sites) nor for any content, goods or services available through them. We
                make no representations about any Linked Sites you may access through the Site whatsoever and we shall not be liable for any loss or damage caused or alleged to be caused to you by
                or in connection with the use of or reliance on any content, goods or services available on or through any Linked Sites.
            </p>
            <p>
                7. Content you provide to us
                All information relating to you is subject to our Privacy Policy that is available for review. In addition, the following also applies to all messages, e-mails, ideas, suggestions, concepts or
                other material submitted by you to us (“Content”):
            </p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;you must ensure that all Content submitted to us does not infringe copyright, design, privacy, publicity, data protection, trademark or any other rights of any third party, and is not
                obscene, abusive, threatening, libelous or defamatory of any person; and
                b.&nbsp;&nbsp;&nbsp;&nbsp;you must ensure that the Content does not advertise or otherwise solicit for either funds or goods or services.
            </p>
            <p>
                We have the right to monitor all Content and may edit, reject or remove Content if we believe it does not comply with the above and, in particular, we reserve the right to block
                incoming e-mails and other Content if we believe that their content is or may be inappropriate or otherwise does not comply with the above.
            </p>
            <p>
                You grant us a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license to publish all Content that you submit to us except any portion of the Content that is Personal Data
                (as defined in our Privacy Policy).
            </p>
            <p>
                You have sole responsibility for the Content which you submit to us and you shall indemnify and shall keep us fully and effectively indemnified on demand from and against all actions,
                claims, losses, liability, proceedings, damages, costs, expenses, loss of business, loss of profits, business interruption and other pecuniary or consequential loss (including legal costs
                and expenses) suffered or incurred by us and arising directly or indirectly out of the publication of Content submitted by you to us.
            </p>
            <p>
                8. Alterations to These Terms
                We reserve the right to amend these Terms, in whole or in part, at any time and you will be notified of the same by our posting of such Terms on the Site. Your continued use of the Site
                after any changes have been posted on the Site will be considered acceptance by you of any changes. Unless explicitly stated to the contrary any new features including new content,
                our provision of new services, or resources shall be subject to these Terms. You should therefore refer to these Terms on a regular basis to ensure the Terms that apply at that time are
                understood.
            </p>
            <p>
                9. Security
                You acknowledge and accept that the Site involves transmissions over the Internet and that such transmissions are therefore subject to the Internet’s inherent risks.
            </p>
            <p>You acknowledge and accept that, as with any network, you may be exposed to:</p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;unauthorized invasions of privacy during, or as a result of, using the Site;
                b.&nbsp;&nbsp;&nbsp;&nbsp;system-threatening viruses and other unauthorized and invasive programs transmitted by third parties;
                c.&nbsp;&nbsp;&nbsp;&nbsp;unauthorized dissemination and publication to others of information and material originally sent only to selected recipients on or through the Site;
                d.&nbsp;&nbsp;&nbsp;&nbsp;eavesdropping and electronic trespassing;
                e.&nbsp;&nbsp;&nbsp;&nbsp;fraud and forgery; and
                f.&nbsp;&nbsp;&nbsp;&nbsp;the failure of information and data to reach their intended destinations and/or erroneous receipt or misdirection of such information.
            </p>
            <p>
                Although our privacy and security features are designed to reduce these risks, we cannot guarantee their elimination. It is your responsibility to ensure that your systems comply with<b/>
                best industry practices. You also acknowledge that no transmission via the Site shall be deemed confidential and that we shall not be liable for any breach of confidence arising as a
                result of such an event.
            </p>
            <p>
                10. Reliance on Information
                As the Site is provided by means of website and telecommunication systems (including local loop network providers and other connectivity arrangements over which we have no
                control) you acknowledge and agree that:
            </p>
            <p>
                a.&nbsp;&nbsp;&nbsp;&nbsp;We will try to make the Site available but cannot guarantee that the Site will operate continuously or be error-free (there may be, without limitation, errors in software used to
                provide the Site and the Information and errors caused by telecommunications links) and we shall be under no liability for the consequences arising from any such error or the reliance
                by you upon the Site.
                b.&nbsp;&nbsp;&nbsp;&nbsp;Time shall not be of the essence in relation to our delivery and/or provision of the Site including without limitation provision of the Information and/or Publications.
                c.&nbsp;&nbsp;&nbsp;&nbsp;Information, publications, figures, rates, approvals, accreditations and recommendations made available on the Site are not intended to amount to advice on which any reliance
                should be placed. We, therefore, disclaim all liability for any reliance you place on such Information, Publications, figures, rates, approvals, accreditations and recommendations.
                d.&nbsp;&nbsp;&nbsp;&nbsp;You should independently verify all Information, figures and rates provided on the Site prior to your use of such Information, figures and rates. We may change, update or correct
                any such Information, figures or rates at any time without notice. Please note that the Site may contain typographical errors and other inaccuracies.
            </p>
            <p>
                11. Limitations of Liability
                Save for where mentioned otherwise in these Terms, we exclude all other express or implied terms, conditions, warranties, representations, or endorsements whatsoever in regard to
                the Site or any Information provided through the Site.
            </p>
            <p>
                Whilst we will use reasonable endeavors to ensure that all content, materials and Information published on the Site are accurate, all content, materials and Information on the Site are
                provided on an ‘as is’ basis. As such, you assume total responsibility and risk for your use of the content, materials, and Information.  We cannot, and do not, accept any responsibility
                or liability for any inaccurate information published to the Site.
            </p>
            <p>
                We accept no liability for any indirect or consequential loss or damage, or for any loss of data, profit, revenue or business (whether direct or indirect) in each case, however, caused,
                even if foreseeable. In circumstances where you suffer loss or damage arising out of or in connection with the viewing, use or performance of the Site we accept no liability for this loss
                or damage (except where we have been negligent) whether due to inaccuracy, error, omission or any other cause and whether on our part or our servants, agents or any other person
                or entity.
            </p>
            <p>
                Notwithstanding any other provision of these Terms, we do not attempt to exclude or limit our liability for death or personal injury resulting from our negligence or for our fraudulent
                representation in any way.
            </p>
            <p>
                You are responsible for ensuring that your computer system meets all relevant technical specifications necessary to use the Site and is compatible with the Site. You also understand that
                we cannot and do not guarantee or warrant that any material available for downloading from the Site will be free from infection, viruses and/or other code that has contaminating or
                destructive properties. You are responsible for taking sufficient precautions and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for
                the accuracy of data input and output.
            </p>
            <p>The limitations and exclusions set out in this section do not affect your non-excludable statutory rights and only apply to the extent permitted by applicable law.</p>
            <p>
                The Site is controlled and operated by us from our offices in Israel. We make no representations that the Site is appropriate or available for use in other locations. Those who visit the
                Site from other locations do so at their own initiative and are responsible for compliance with all applicable laws. If the use of this Site and/or viewing of it or use of any material or
                content on the Site, is contrary to or infringes any applicable law in your jurisdiction(s), you are not authorized to view or use the Site and you must exit immediately.
            </p>
            <p>
                12. General
                No rights are conferred on any person who is not a party to these Terms.
            </p>
            <p>
                Each provision contained in these Terms shall be severable from any other provision, and if any part of any provision shall be found to be invalid, illegal or void for any reason, then
                such finding shall not affect any other part of such provision or any other provision contained in the Terms which shall continue to have full force and effect.
            </p>
            <p>
                Any failure or delay by us enforcing an obligation or exercising a right, under these Terms, does not amount to a waiver of that obligation or right, or any other obligation or right.
            </p>
            <p>
                You acknowledge that you have not entered into these Terms in reliance on any statement or representation, except in so far as the representation has been incorporated into these
                Terms. You irrevocably and unconditionally waive any right you may have to claim damages and/or to rescind these Terms by reason of any misrepresentation (other than a fraudulent
                misrepresentation) not contained in these Terms.
            </p>
            <p>
                You shall not assign any of your rights or obligations under these Terms without our prior written consent. We shall have the right to assign or otherwise delegate all or any of our rights
                or obligations under these Terms.
            </p>
            <p>
                We do not endorse, guarantee or warrant any product or service sold by a third party, nor do we have any liability or responsibility for the fitness for purpose or quality or delivery of
                any product or service sold to you by third parties or otherwise. The purchase or receipt by you of any product or service from a third party is a transaction solely between you and that
                third party.
            </p>
        </>
    )
}
export default TermsAndConditionsModal




