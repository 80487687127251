import AuthPage from "../AuthPage/AuthPage";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import ErrorModal from "../ErrorModal";
import img from "../../assets/images/main/joe.png"

function SingInWithPhenom({ isLoading, apiError, signInWithPhenom, phenomError, handleClose }) {
    const [url, setUrl] = useState('')
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code") ?? '';
    const state = searchParams.get("state") ?? ''
    const [user, setUser] = useState(null)
    const navigate = useNavigate();

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_URL}/auth/self`)
            .then(res => res.json())
            .then(res => {
                setUser(res.user)
                if(res.success){
                    navigate("/StocksPortfolioBuilder");
                }
            })
            .catch(err => console.log(err.message));
    },[navigate])

    useEffect(() => {
        if(!user){
            signInWithPhenom(code, state, setUrl)
        }
    },[code, state])


    const handleRedirect = () => {
        window.location.assign(url);
    }
    const text = phenomError === 423
      ? "Your access to the Manitorum was blocked."
      : "Unfortunately your tariff package does not provide access to the Manitorium."
    const contact = phenomError === 423
      ? "Please contact your manager from The Key project to find the reason."
      : "Contact “The Key” support."
    return (
      <>
          {phenomError && <ErrorModal contact={contact} text={text} img={img} handleClose={handleClose}/>}
          <section>
              <AuthPage
                type="phenom"
                welcome="Welcome to Manitorium!"
                title="Sign in"
                submitText={!isLoading ? "Sign In with The Key" : "Loading"}
                redirectLink={url}
                redirectUrl={handleRedirect}
                isDisabled={code || state}
                isLoading={code || state}
                {...{ isLoading, apiError }}
              />
          </section>
      </>

    )
}
export default SingInWithPhenom
