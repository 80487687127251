import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  color: true,
  user: {},
  admin: false,
  moderator: false,
  name: '',
  tickers: '',
  type: ''
};
export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setColor: (state, action) => {
        console.log(state);
      state.color = action.payload;
    },
    setName: (state, action) => {state.name = action.payload},
    setUser: (state, action) => {
      console.log('User for redux', action.payload);
      return {...state, user: action.payload}
    },
    setAdmin: (state, action) => {state.admin = action.payload},
    setModerator: (state, action) => {state.moderator = action.payload},
    setTickers: (state, action) => {
      return {...state,tickers: action.payload}
    },
    setPortfolioType: (state, action) => {state.type = action.payload}
  },
});

export const {
 setColor , setUser, setTickers, setName , setAdmin, setModerator, setPortfolioType,
} = counterSlice.actions;

export const new_color = (state) => state.counter.color;
export const name = (state) => state.counter.name;
export const user = (state) => state.counter.user;
export const tickers = (state) => state.counter.tickers;
export const admin = (state) => state.counter.admin;
export const moderator = (state) => state.counter.moderator;
export const portfolioType = (state) => state.counter.type;



export default counterSlice.reducer;
