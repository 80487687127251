import ScrollArea from "react-scrollbar"
import close from "../../../../assets/images/close.svg";
import TermsAndConditionsModal from "../TermsAndConditionsModal";
import "./modal.style.css"
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import DisclaimerModal from "../DisclaimerModal";

const modalTitle ={
    privacy: "PRIVACY POLICY",
    agreement: "TERMS OF USE",
    disclaimer: "Disclaimer"
}

const SelectModal = ({modalType}) => {
    switch (modalType) {
        case "agreement":
            return <TermsAndConditionsModal />;
        case "privacy":
            return <PrivacyPolicyModal />;
        case "disclaimer":
            return <DisclaimerModal />;
        default:
            return null;
    }
};


const InfoModal = ({ setViewModal,modalType }) => {
    const title = modalTitle[modalType]
    return(
        <div className="modal__wrapper">
            <div className="modal__info">
            </div>
            <div className="info">
                    <img src={close} alt='close' onClick={setViewModal} className="close__button"/>
                    <h3>{title.toUpperCase()}</h3>
                    <ScrollArea
                        speed={0.8}
                        className="area"
                        smoothScrolling={true}
                        horizontal={false}
                        verticalScrollbarStyle={{width: 3, backgroundColor:"black"}}
                        verticalContainerStyle={{width: 4}}
                    >
                        <SelectModal modalType={modalType}/>
                    </ScrollArea>
                </div>
        </div>

    )
}
export default InfoModal
