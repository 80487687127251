import "./deleteModal.style.css"

const DeleteModal = ({handleDelete, handleClose}) => {
    return (
        <div className="delete__modal_container" onClick={(e) => e.stopPropagation()}>
            <h2>Are you sure you want to delete?</h2>
            <div className="button_group">
                <button onClick={handleDelete} className="del__button">Delete</button>
                <button onClick={handleClose} className="del__button cancel__button">Cancel</button>
            </div>
        </div>
    )
}
export default DeleteModal
